html, body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-bootstrap-table {
  height: calc(100vh - 145px);
  overflow: auto;
}

.react-bootstrap-table .table {
  table-layout: unset;
}

.react-bootstrap-table-pagination {
  width: 100vw;
}

.MuiPopover-root {
  width: 100vw;
}

#root {
  width: 100vw;
}