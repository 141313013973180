.create-user-form {
    margin-left: auto;
    margin-right: auto;
    max-width: 50vw;
    margin-top: 5px;

    @media only screen and (max-width: 600px) {
        max-width: 100vw;
        margin-left: 5px;
        margin-right: 5px;
    }
}